import gql from "graphql-tag";

export const HOME = gql`
  query Home {
    page(id: "/home", idType: URI) {
      id
      home {
        text
        title
        image {
          altText
          mediaItemUrl
        }
      }
    }
  }
`;

export const GALLERY = gql`
  query Gallery {
    page(id: "/home", idType: URI) {
      id
      gallery {
        galleryTitle
        homeGallery {
          id
          sourceUrl
          altText
        }
      }
    }
  }
`;
