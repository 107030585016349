<template>
  <div class="video-background" :style="{ height: wph + 'px' }">
    <!-- <video
      autoplay=""
      loop=""
      muted=""
      playsinline=""
      class="vids"
      poster="https://nf-architecture.io/wp-content/uploads/2021/12/nfa-preview-snap.jpg"
    >
      <source
        src="https://nf-architecture.io/wp-content/uploads/2021/12/nfa-preview.mp4"
        type="video/mp4"
      />
    </video> -->
  </div>
</template>

<script>
export default {
  name: "VideoBanner",
  data() {
    return {
      wph: window.innerHeight,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  methods: {
    onResize() {
      this.wph = window.innerHeight;
    },
  },
};
</script>

<style scoped lang="scss">
.video-background {
  //   height: 100vh;
  width: 100%;
  position: relative;
  background-image: url("~@/assets/spei_3.jpg");
  background-size: cover;
  background-position: center center;
}
.vids {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: 0;
}

.video-background:before {
  content: "";
  position: absolute;
  // background: rgba(158, 37, 37, 0.3);
  border-radius: 5px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 12456;
}
</style>