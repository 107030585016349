<template>
  <!-- <div id="nav">
    <Nav />
    <DoubleLine />
  </div> -->
  <router-view />
  <div id="footer">
    <DoubleLine />
    <Footer />
  </div>
</template>

<script>
// import Nav from "./components/Nav";
import Footer from "./components/Footer";
import DoubleLine from "./components/DoubleLine";

export default {
  components: { Footer, DoubleLine },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Jura:wght@400;700&display=swap");
body {
  margin: 0;
  font-family: "Jura", sans-serif;
}
.container {
  max-width: 1750px;
  margin: auto;
  padding: 0 25px;
}
html {
  scroll-behavior: smooth;
}
html,
body {
  max-width: 100%;
  overflow-x: hidden;
}
</style>
