<template>
  <div class="container c-flex">
    <div class="left">
      <h3>Marc Wieneke</h3>
      <!-- <p>T: +34 (0)11231212</p> -->
      <p>info@marcwieneke.com</p>
    </div>
    <div class="right">
      <p>
        <router-link to="/privacy">Privacy policy & terms of use</router-link>
      </p>
      <p>{{ `© ${new Date().getFullYear()} Marc Wieneke` }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped lang="scss">
.c-flex {
  display: flex;
  place-items: flex-end;
  place-content: space-between;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    place-items: baseline;
    * {
      text-align: left;
    }
  }
  //   background-color: #ffffff;
  //   opacity: 1;
  //   background-size: 35px 35px;
  //   background-image: repeating-linear-gradient(
  //     45deg,
  //     #c8e4ec 0,
  //     #c8e4ec 2.1px,
  //     #ffffff 0,
  //     #ffffff 50%
  //   );
  .right {
    text-align: right;
    padding: 20px 0;
  }
  .left {
    padding: 20px 0;
  }
  a {
    color: black;
    text-decoration: none;
  }
}
</style>