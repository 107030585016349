<template>
  <div class="blob">
    <div class="logo">
      <img src="@/assets/halogo.png" alt="" />
    </div>
    <div class="text">
      <h3>haute architecture</h3>
      <p><a href="#Marc">Marc</a> <span>|</span> <a href="#work">Work</a></p>
      <p>by Marc Wieneke</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderBlob",
};
</script>

<style scoped lang="scss">
.blob {
  display: flex;
  position: absolute;
  bottom: 40px;
  left: 0;
  z-index: 14512345;
  background: rgba(255, 255, 255, 0.8);
  padding: 20px;
  padding-right: 100px;
  border-radius: 0 100px 100px 0;
  border: 2px solid #701818;
  border-left: 0;
  @media only screen and (max-width: 768px) {
    padding-right: 50px;
  }
  .logo {
    max-width: 150px;
    margin-right: 20px;
    img {
      width: 100%;
      height: auto;
    }
  }
  .text {
    place-self: center;
    * {
      margin: 2px 0;
    }
    a {
      color: black;
      text-decoration: none;
    }
    p {
      font-size: 18px;
    }
    span {
      color: #701818;
    }
  }
}
</style>