<template>
  <div class="line1"></div>
  <!-- <div class="line2"></div> -->
</template>

<script>
export default {
  name: "DoubleLine",
};
</script>

<style scope lang="scss">
.line1 {
  // margin-bottom: 8px;
  border-bottom: 2px solid #701818;
}
.line2 {
  border-bottom: 2px solid #701818;
}
</style>