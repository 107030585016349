<template>
  <div class="home">
    <HeaderBlob />
    <VideoBanner />
    <HomeHero />
    <Gallery />
  </div>
</template>

<script>
import HomeHero from "../components/HomeHero";
import Gallery from "../components/Gallery";
import VideoBanner from "../components/VideoBanner";
import HeaderBlob from "../components/HeaderBlob";
export default {
  name: "Home",
  components: { HomeHero, Gallery, VideoBanner, HeaderBlob },
};
</script>
