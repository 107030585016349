<template>
  <div class="container cb">
    <div v-if="usableGalleryData">
      <h2 id="work">{{ usableGalleryData.galleryTitle }}</h2>
      <div class="gallery">
        <div
          v-for="image in usableGalleryData.homeGallery"
          :key="image.id"
          :class="`image-${image.id} gallery-image-container`"
        >
          <img :src="image.sourceUrl" :alt="image.altText" />
        </div>
      </div>
    </div>
    <div v-else>loading....</div>
  </div>
</template>

<script>
import { GALLERY } from "../lib/api";
export default {
  name: "Gallery",
  data() {
    return {
      page: {},
    };
  },
  apollo: {
    page: GALLERY,
  },

  computed: {
    usableGalleryData: function () {
      if (this.page) {
        return this.page.gallery;
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
h2 {
  margin: 0;
  padding: 0.85em 0;
}
.gallery {
  .gallery-image-container {
    max-width: 100%;
    img {
      width: 100%;
      height: auto;
      // border: #701818 2px solid;
      // box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    }
  }
}

// .cb {
//   background-color: #ffffff;
//   opacity: 1;
//   background-size: 35px 35px;
//   background-image: repeating-linear-gradient(
//     135deg,
//     #d0e0e5 0,
//     #d0e0e5 1.4px,
//     #ffffff 0,
//     #ffffff 50%
//   );
// }

.gallery {
  columns: 2 auto;
  column-gap: 1rem;
  @media only screen and (max-width: 768px) {
    columns: 1 auto;
  }
  div {
    color: white;
    margin: 0 0 0.5rem 0;
    display: inline-block;
    width: 100%;
    text-align: center;
    font-family: system-ui;
    font-weight: 900;
    font-size: 2rem;
  }
}
</style>